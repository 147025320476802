import classNames from "classnames";
import Image from "next/image";
import Title from "../atoms/Title";
const TabItems = ({ tabs = [], onSelect, classes = {}, active }) => {
  const defaultClasses = {
    tabContainer: "flex justify-center gap-3 items-center flex-wrap",
    active: " text-white bg-main-blue-king font-medium  py-2 px-4 rounded-full",
    inactive:
      "text-white hover:bg-main-blue-king/[0.50] py-2 px-4 rounded-full",
    tab: "font-subtitle text-sm uppercase",
    subtitle: "font-subtitle text-xs opacity-75",
  };
  return (
    <div className={classes?.tabContainer || defaultClasses?.tabContainer}>
      {tabs.map((tab, key) => {
        const classbutton =
          active === key
            ? classes?.active || defaultClasses?.active
            : classes?.inactive || defaultClasses?.inactive;
        const image = active === key ? tab.iconActive : tab.icon;

        return (
          <button
            key={"button-tab-" + key}
            onClick={() => onSelect(key)}
            className={classNames(classbutton)}
          >
            {image && image.src ? (
              <Image
                src={image?.src}
                height={image?.height}
                width={image?.width}
                alt="icon"
                className="mx-auto"
              />
            ) : (
              ""
            )}
            {
              tab?.tag ? 
              <Title tag={tab?.tag || "h2"} className={classNames(classes?.tab || defaultClasses.tab)}>
                {tab?.tab}
                </Title> 
                :
                <p className={classNames(classes?.tab || defaultClasses.tab )}>
              {tab?.tab}
            </p>
            } 
            <small
              className={classNames(
                classes?.subtitle || defaultClasses.subtitle
              )}
            >
              {tab?.subtitle}
            </small>
          </button>
        );
      })}
    </div>
  );
};

export default TabItems;
