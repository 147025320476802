import { useEffect, useState } from "react";
import TabItems from "@/components/molecules/TabItems";
import TabContent from "@/components/organisms/TabContent";
import DropdownTabs from "@/components/molecules/DropdownTabs";
import CounterTabs from "@/components/molecules/CounterTabs";
import classNames from "classnames";
import arrowimage from "@/public/images/icons/down-arrow.svg";
import prev from "@/public/images/icons/prev.svg";
import next from "@/public/images/icons/next.svg";
import TabSlider from "@/components/molecules/TabSlider";

const Tabs = ({
  tabs = [],
  classes,
  type = "select",
  arrow = arrowimage,
  counter = true,
  arrowPrev = prev,
  arrowNext = next,
  arrowPrevSlider = prev,
  arrowNextSlider = next,
  imageFirst = false
}) => {
  const [active, setActive] = useState(0);
  const onSelect = (key) => {
    setActive(key);
  };
  const onChange = (action) => {
    switch (action) {
      case "prev":
        if (active > 0) {
          setActive(active - 1);
        }
        break;
      case "next":
        if (active < tabs.length - 1) {
          setActive(active + 1);
        }
        break;
      default:
    }
  };
  useEffect(() => {
    setActive(0);
  }, [tabs]);
  const styles = {
    container:
      classes?.container || "base-container base-grid gap-0 px-0 md:px-3 ",
    tabs: classes?.tabs || {},
    tabsItemsContainer:
      classes?.tabsItemsContainer ||
      "col-span-12 md:col-span-10 md:col-start-2 bg-main-blue py-8",
    tabContent: classes?.tabContent || {
      pragraphs:"my-auto"
    },
    tabContentContainer:
      classes?.tabContentContainer ||
      "col-span-12 md:col-span-10 md:col-start-2 bg-main-blue",
    counterContainer:
      classes?.counterContainer ||
      "col-span-12 md:col-span-10 md:col-start-2 lg:col-span-4 lg:col-start-2 base-grid bg-main-blue lg:bg-white",
    counter: classes?.counter || {},
  };
  return (
    <div className={classNames(styles?.container)}>
      <div className={classNames(styles?.tabsItemsContainer)}>
        <div className={classNames({ "hidden lg:block": type })}>
          <TabItems
            tabs={tabs}
            onSelect={onSelect}
            classes={styles?.tabs}
            active={active}
          />
        </div>
        {type === "select" ? (
          <div className="lg:hidden">
            <DropdownTabs
              tabs={tabs}
              onSelect={onSelect}
              classes={classes?.dropdown}
              active={active}
              arrow={arrow}
            />
          </div>
        ) : (
          ""
        )}
        {type === "slider" ? (
          <div className="lg:hidden">
            <TabSlider
              tab={tabs[active] || {}}
              classes={styles?.tabs}
              active={active + 1}
              to={tabs?.length || "0"}
              onChange={onChange}
              arrowPrev={arrowPrevSlider}
              arrowNext={arrowNextSlider}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={classNames(styles?.tabContentContainer)}>
        <TabContent tab={tabs[active]} classes={styles?.tabContent} imageFirst={imageFirst || false} />
      </div>

      {counter && tabs.length ? (
        <div className={classNames(styles?.counterContainer)}>
          <CounterTabs
            classes={classes?.counter}
            active={active + 1}
            to={tabs?.length || "0"}
            onChange={onChange}
            arrowPrev={arrowPrev}
            arrowNext={arrowNext}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default Tabs;
