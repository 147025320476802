import Link from "next/link";
import Text from "../atoms/Text";
import Title from "../atoms/Title";

const Hero = (props) => {
  const title = props.title
    ? {
        tag: props.title?.tag || "h2",
        text: props.title?.text || "",
        className: props.title?.className || "hero-title md:mb-7",
      }
    : false;
  const text = props.text
    ? {
        text: props.text?.text || "",
        className: props.text?.className || "hero-text mb-4",
      }
    : false;
    const secondTitle = props.secondTitle
    ? {
        text: props.secondTitle?.text || "",
        className: props.secondTitle?.className || "hero-text mb-4",
        tag: props.secondTitle?.tag || "h3",
      }
    : false;
  const paragraphs =
    props.paragraphs && Array.isArray(props.paragraphs.list)
      ? {
          list: props.paragraphs?.list || "",
          className:
            props.paragraphs?.className || "hero-text mb-4 whitespace-pre-line",
        }
      : false;
  const link = props.link
    ? {
        text: props.link?.text || "",
        href: props.link?.href || "",
        className: props.link?.className || "hero-link mb-4",
      }
    : false;
  const styles = {
    heroContainer: props.styles?.heroContainer || "base-container py-12",
    titleContainer: props.styles?.titleContainer || "col-span-12",
    textContainer: props.styles?.textContainer || "col-start-2 col-span-10",
    paragraphsContainer:
      props.styles?.paragraphsContainer || "col-start-2 col-span-10",
    linkContainer: props.styles?.linkContainer || "col-span-12 text-center",
    baseGrid: props.styles?.baseGrid || "base-grid",
  };

  return (
    <>
      <div className={styles.heroContainer}>
        <div className={styles.baseGrid}>
          {title && (
            <div className={styles.titleContainer}>
              <Title tag={title.tag} className={title.className}>
                {title.text}
              </Title>
            </div>
          )}
          {secondTitle && (
            <div className={styles.textContainer}>
              <Title tag={secondTitle.tag} className={secondTitle.className}>
                {secondTitle.text}
              </Title>
            </div>
          )}
          {text && (
            <div className={styles.textContainer}>
              <Text className={text.className}>{text.text}</Text>
            </div>
          )}
          {paragraphs && (
            <div className={styles.paragraphsContainer}>
              {paragraphs.list.map((p, index) => (
                <Text key={index} className={paragraphs.className}>
                  {p}
                </Text>
              ))}
            </div>
          )}
          {link && (
            <div className={styles.linkContainer}>
              <Link href={link.href} className={link.className}>
                {link.text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
