import ImageCarousel from "./ImageCarousel";
import Paragraphs from "@/components/molecules/Paragraphs";

import classNames from "classnames";

const settings = {
  slidesPerView: 1,
  spaceBetween: 0,
};
const TabContent = ({ tab = {}, classes = {}, imageFirst = false }) => {
  const defaultClasses = {
    contentContainer: "grid grid-cols-12 gap-4 lg:gap-4 ",
    imagesContainer: "col-span-12 lg:col-span-7 lg:-mb-20 lg:-mr-9",
    paragraphsContainer:
      "col-span-12 lg:col-span-5 z-10 col-start-1 px-3 pb-6 lg:px-6 xl:px-12 my-auto",
  };
  return (
    <div
      className={classNames(
        classes?.contentContainer || defaultClasses?.contentContainer
      )}
    >
      { imageFirst &&
      <div
        className={classNames(
          classes?.imagesContainer || defaultClasses?.imagesContainer
        )}
      >
        {(tab?.images && tab?.images.length && (
          <ImageCarousel images={tab?.images} classes={classes?.images} />
        )) ||
          ""}
      </div> }
      <div
        className={classNames(
          classes?.paragraphsContainer || defaultClasses?.paragraphsContainer
        )}
      >
        {tab.paragraphs?.length ? (
          <Paragraphs
            items={tab.paragraphs}
            classes={classNames(
              classes.paragraphs || defaultClasses?.paragraphs
            )}
          />
        ) : (
          ""
        )}
      </div>
      {!imageFirst ?
      <div
        className={classNames(
          classes?.imagesContainer || defaultClasses?.imagesContainer
        )}
      >
        {(tab?.images && tab?.images.length && (
          <ImageCarousel images={tab?.images} classes={classes?.images} />
        )) ||
          ""} 
      </div> :
      <div
      className={classNames(
        classes?.imagesContainerDesktop || defaultClasses?.imagesContainer
      )}
    >
      {(tab?.images && tab?.images.length && (
        <ImageCarousel images={tab?.images} classes={classes?.images} />
      )) ||
        ""} 
    </div>
      }
    </div>
  );
};

export default TabContent;
