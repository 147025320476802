import Image from "next/image";
import classNames from "classnames";
import prev from "@/public/images/icons/prev.svg";
import next from "@/public/images/icons/next.svg";

const defaultClasses = {
  container: "col-span-12 base-grid p-4",
  counter:"col-span-4 lg:col-span-6 mx-auto",
  active: "font-title text-main-blue-king text-6xl font-bold inline",
  divided: "font-title text-main-blue-king text-4xl font-base inline ",
  to: "font-title text-main-blue-king text-[25px] lg:text-[35px] font-base inline ",
  arrows: "col-span-6 ml-auto mr-0 lg:mx-auto flex justify-content-around gap-x-10 ",
};
const CounterTabs = ({
  active,
  to = 1,
  onChange,
  classes,
  arrowPrev = prev,
  arrowNext = next,
}) => {
  return (
    <div
      className={classNames(classes?.container || defaultClasses?.container)}
    >
      <div className={classes?.counter || defaultClasses?.counter}>
        <p className={classNames(classes?.active || defaultClasses?.active)}>
          {active}
        </p>
        <p className={classNames(classes?.divided || defaultClasses?.divided)}>
          /
        </p>
        <p className={classNames(classes?.to || defaultClasses?.to)}>{to}</p>
      </div>
      <div className={classes?.arrows || defaultClasses?.arrows}>
        <button
          className={classNames(classes?.prev || defaultClasses?.prev, {
            "opacity-50": active === 1,
          })}
          onClick={() => onChange("prev")}
        >
          <Image
            src={arrowPrev.src}
            width={arrowPrev.width}
            height={arrowPrev.height}
            alt="arrow"
          />
        </button>
        <button
          onClick={() => onChange("next")}
          className={classNames(classes?.next || defaultClasses?.next, {
            "opacity-50": active === to,
          })}
        >
          <Image
            src={arrowNext.src}
            width={arrowNext.width}
            height={arrowNext.height}
            alt="arrow"
          />
        </button>
      </div>
    </div>
  );
};

export default CounterTabs;
