import React from "react";
import Image from "next/image";
import classNames from "classnames";
import arrowimage from "@/public/images/icons/down-arrow.svg";

const defaultClasses = {
  dropdownContainer: "min-w-[50%] w-fit mx-auto relative",
  dropdown:
    "font-subtitle  flex justify-around gap-3 items-center cursor-pointer px-3 py-3 rounded-full border border-main-blue bg-main-blue-king",
  active: "text-white uppercase font-bold ",
  inactive:
    "font-subtitle uppercase hover:bg-medium-grey hover:text-white text-main-grey px-6 pt-3 pb-2 cursor-pointer bg-white border border-medium-grey",
  subtitle: "opacity-75 ",
  select: "bg-neutral-30  w-full mt-1 text-left max-h-64 overflow-y-auto",
};
const DropdownTabs = ({
  tabs = [],
  onSelect,
  classes = {},
  active,
  arrow = arrowimage,
  defaultText = undefined
}) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(false);
  }, [active]);
  return (
    <div
      className={classNames(
        classes?.dropdownContainer || defaultClasses?.dropdownContainer
      )}
    >
      <div
        className={classNames(
          classes?.dropdown || defaultClasses?.dropdown,
          classes?.active || defaultClasses?.active
        )}
        onClick={() => setOpen(!open)}
      >
        <div className="">
          <p className={classNames(classes?.tab || defaultClasses?.tab)}>
            {tabs[active]?.tab || defaultText || "sin seleccionar"}
          </p>
          <small
            className={classNames(
              classes?.subtitle || defaultClasses?.subtitle
            )}
          >
            {tabs[active]?.subtitle || ""}
          </small>
        </div>
        <div>
          <Image
            src={arrow.src}
            width={arrow.width}
            height={arrow.height}
            className={classNames({ "rotate-180": open })}
            alt="arrow"
          />
        </div>
      </div>

      <div className="absolute  w-full flex justify-center z-20">
        <div
          className={classNames(classes?.select || defaultClasses?.select, {
            hidden: !open,
          })}
        >
          {tabs.map((tab, key) => {
            return (
              <div
                key={"button-tab-" + key}
                onClick={() => onSelect(tab?.key || key)}
                className={classNames(
                  classes?.inactive || defaultClasses?.inactive,
                  "cursor-pointer"
                )}
              >
                <p className={classNames(classes?.tab || defaultClasses?.tab)}>
                  {tab?.tab}
                </p>
                <small
                  className={classNames(
                    classes?.subtitle || defaultClasses?.subtitle
                  )}
                >
                  {tab?.subtitle}
                </small>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropdownTabs;
