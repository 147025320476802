import React from "react";
import Link from "next/link";
import classNames from "classnames";
import Title from "../atoms/Title";
const Paragraphs = ({ items, classes }) => {
  return (
    <div className={classNames(classes)}>
      {items?.map((item, key) => {
        return (
          <React.Fragment key={"text-" + key}>
            {!item.type || item.type === "text" ? (
              <p
                className={classNames(
                  item?.classes || item?.className || "font-subtitle"
                )}
              >
                {item?.text}
              </p>
            ) : (
              ""
            )}
            {item?.type === "link" ? (
              <Link href={item.href || "#"} legacyBehavior>
                <a
                  key={"text-" + key}
                  className={classNames(
                    classNames(
                      item?.classes || item?.className || "font-subtitle"
                    )
                  )}
                  target={item?.target || ""}
                  aria-label={item?.accesibleName}
                  id={item?.seeMenuId || item?.discoverId || ""}
                >
                  {item.text}
                </a>
              </Link>
            ) : (
              ""
            )}
             {item?.secondText && 
            <Link href={item?.href || "/"} legacyBehavior>
            <a
              key={"text-" + key}
              className={"block w-full " + classNames(
                classNames(
                  item?.classes || item?.className || "font-subtitle"
                )
              )}
              target={item?.target || ""}
            >
              {item.secondText}
            </a>
          </Link>
            }
            {item.type === "other" ? (
              <div
                className={classNames(
                  item?.classes || item?.className || "font-subtitle"
                )}
              >
                {item?.text}
              </div>
            ) : (
              ""
            )}
            {
              item?.type === "title" && 
              <Title tag={item?.tag || "h1"} className={item?.classes || ""}>
                {item?.text}
                </Title>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default Paragraphs;
