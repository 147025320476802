import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import Image from "next/image";
import classNames from "classnames";

const INIT_CONFIG = {
  initialSlide: 0,
  spaceBetween: 0,
  slidesPerView: 1,
  loop: false,
  style: {},
  navigation: false,
  className: "",
  autoplay: false,
};
const ImageCarousel = ({ images, classes = {}, config = INIT_CONFIG }) => {
  return (
    <Swiper
      initialSlide={config?.initialSlide || 0}
      spaceBetween={config?.spaceBetween || 0}
      slidesPerView={config?.slidesPerView || 1}
      loop={config?.loop || false}
      style={config?.style || {}}
      navigation={config?.navigation || false}
      className={classNames(classes?.swiper, "swiper-image-carousel h-full")}
      modules={[Navigation, Autoplay]}
      autoplay={config?.autoplay || false}
    >
      {images.map((image, key) => {
        const imageProps = {
          ...image,
          fill: true,
          src: image.src || image.url,
        };
        if (imageProps?.fill) {
          delete imageProps.width;
          delete imageProps.height;
        }
        return (
          <SwiperSlide
            key={key}
            className={classNames(classes?.swiperSlide, " relative")}
          >
            <div className={classNames("w-full h-full grid")}>
              <Image
                src={image.src || image.url}
                height={image?.height || 455}
                width={image?.width || 635}
                className={classNames(
                  classes?.image,
                  "w-full h-full object-cover"
                )}
                alt={image?.alt || "image-slider-" + key}
                unoptimized
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
export default ImageCarousel;
