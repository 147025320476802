import classNames from "classnames";
import Image from "next/image";
import prev from "@/public/images/icons/prev.svg";
import next from "@/public/images/icons/next.svg";
const TabSlider = ({
  tab = {},
  to = 1,
  onChange,

  classes = {},
  active,
  arrowPrev = prev,
  arrowNext = next,
}) => {
  const defaultClasses = {
    tabContainer:
      "flex justify-between md:justify-around gap-3 items-center flex-wrap",
    active:
      "font-subtitle text-white bg-main-blue-king font-bold  py-2 px-4 rounded-full",
    inactive:
      " text-main-grey hover:bg-main-blue-king/[0.50] py-2 px-4 rounded-full",
    tab: "font-subtitle text-xs uppercase",
    subtitle: "font-subtitle text-xs opacity-75",
  };
  return (
    <div className={classes?.tabContainer || defaultClasses?.tabContainer}>
      <button
        className={classNames(classes?.prev || defaultClasses?.prev, {
          "opacity-50": active === 1,
        })}
        onClick={() => onChange("prev")}
      >
        <Image
          src={arrowPrev.src}
          width={arrowPrev.width}
          height={arrowPrev.height}
          alt="arrow"
        />
      </button>
      <div className={classNames(classes?.active || defaultClasses?.active)}>
        {tab?.iconActive && tab?.iconActive?.src ? (
          <Image
            src={tab?.iconActive?.src}
            height={tab?.iconActive?.height}
            width={tab?.iconActive?.width}
            alt="icon"
            className="mx-auto"
          />
        ) : (
          ""
        )}
        <p className={classNames(classes?.tab || defaultClasses.tab)}>
          {tab?.tab}
        </p>
        <small
          className={classNames(classes?.subtitle || defaultClasses.subtitle)}
        >
          {tab?.subtitle}
        </small>
      </div>

      <button
        onClick={() => onChange("next")}
        className={classNames(classes?.next || defaultClasses?.next, {
          "opacity-50": active === to,
        })}
      >
        <Image
          src={arrowNext.src}
          width={arrowNext.width}
          height={arrowNext.height}
          alt="arrow"
        />
      </button>
    </div>
  );
};

export default TabSlider;
